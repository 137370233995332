import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoggedUser} from '../interfaces/logged-user';
import {environment} from '../../../../environments/environment';
import {AuthLocalStorageName, InterceptorSkipHeader} from '../../../core/classes/const';
import {HttpDataItemsResponse} from '../../../core/interfaces/http-data-items-response';
import {CurrentUser} from "../interfaces/current-user";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  login(login: any) {
    const headers = {};
    headers[InterceptorSkipHeader] = ['AUTH'];

    return this.http.post<LoggedUser>(environment.api.auth.login_check, {
      username: login.email,
      password: login.password
    }, {
      headers
    }).toPromise();
  }

  refreshPermissions() {
    return this.http.get<HttpDataItemsResponse<string>>(environment.api.auth.permission).toPromise().then(res => {
      const user = this.getUser();
      this.setUser(user);
    }, reject => {
      this.logout();
    });
  }

  fetchCurrentUser() {
    return this.http.get<CurrentUser>(environment.api.users.current_user).toPromise().then(res => {
      const loggedUser = this.getUser();
      loggedUser.current_user = res;
      this.setUser(loggedUser);
    }, reject => {
      this.logout();
    });
  }

  refreshToken() {
    const headers = {};
    headers[InterceptorSkipHeader] = ['AUTH'];
    return this.http.post<LoggedUser>(environment.api.auth.refresh, {
      refresh_token:  this.getUser()?.refresh_token,
    }, {
      headers
    }).toPromise().then((res)=>{

      if(!this.getUser().current_user){
        this.fetchCurrentUser();
      }

      this.setUser(res);
      return this.getUser();
    });
  }
  //
  // getNewToken(refresh_token?): Observable<LoggedUser> {
  //   const headers = {};
  //   headers[InterceptorSkipHeader] = ['AUTH']
  //
  //   if ( ! refresh_token) {
  //     refresh_token = this.getUser().refresh_token;
  //   }
  //   return this.http.post<LoggedUser>(environment.api.auth.refresh, {
  //     refresh_token:  this.getUser()?.refresh_token,
  //   },{headers}).pipe(
  //     map((res: any) => {
  //       return res;
  //     })
  //   );
  // }

  logout() {
    localStorage.removeItem(AuthLocalStorageName);
  }

  isAuthenticated(): boolean {
    return this.getUser() !== null;
  }

  hasRole(role): boolean {
    //Fixme refresh token
    if(!this.getUser()?.current_user?.roles){
      return false;
    }
    return this.getUser().current_user?.roles.indexOf(role) !== -1;
  }

  hasPermission(permission): boolean {
    //Fixme refresh token
    if(!this.getUser().current_user?.roles){
      return false;
    }
    return this.getUser().current_user.permissions.indexOf(permission) !== -1;
  }

  getUser(): LoggedUser | null {
    return localStorage.getItem(AuthLocalStorageName)
      ? JSON.parse(String(localStorage.getItem(AuthLocalStorageName))) as LoggedUser
      : null;
  }

  setUser(user: LoggedUser) {
    return localStorage.setItem(AuthLocalStorageName, JSON.stringify(user));
  }

  changePassword(data): Promise<any> {
    return this.http.put<any>(environment.api.auth.change, data).toPromise();
  }

  forgotPassword(data): Promise<any> {
    return this.http.post<any>(environment.api.auth.forgot, data).toPromise();
  }
}
