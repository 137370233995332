<div class="app-sidebar">
  <h1 routerLink="/investments">Tadmar Inwestycje</h1>
  <nav>
    <ul>
      <li>
        <a routerLink="/home" routerLinkActive="active" matTooltip="Start"
           matTooltipPosition="right">
          <i class="material-icons">home</i>
        </a>
      </li>
      <li>
        <a routerLink="/investments" routerLinkActive="active" matTooltip="Inwestycje"
           matTooltipPosition="right">
          <i class="material-icons">settings</i>
        </a>
      </li>
      <li>
        <a routerLink="/timetable" routerLinkActive="active" matTooltip="Terminarz" matTooltipPosition="right">
          <i class="material-icons">calendar_today</i>
        </a>
      </li>
      <li *ngIf="guideUrl">
        <a target="_blank" [href]="guideUrl"  matTooltip="Poradnik inwestycyjny" matTooltipPosition="right">
          <i class="material-icons">assignment</i>
        </a>
      </li>
      <li *ngIf="auth?.hasRole('ROLE_CENTRALA') || auth?.hasRole('ROLE_ADMIN') || auth?.hasRole('ROLE_DIRECTOR_REGIONAL') || auth?.hasRole('ROLE_DIRECTOR_DEPARTMENT')">
        <a routerLink="/stats" routerLinkActive="active" matTooltip="Statystyki" matTooltipPosition="right">
          <i class="material-icons">pie_chart</i>
        </a>
      </li>
      <li *ngIf="auth?.hasRole('ROLE_ADMIN')">
        <a routerLink="/users" routerLinkActive="active" matTooltip="Pracownicy" matTooltipPosition="right">
          <i class="material-icons">people</i>
        </a>
      </li>
      <li *ngIf="auth?.hasRole('ROLE_ADMIN')">
        <a routerLink="/settings" routerLinkActive="active" matTooltip="Ustawienia" matTooltipPosition="right">
          <i class="material-icons">tune</i>
        </a>
      </li>
    </ul>
    <div>
      <button class="user-logged" [matMenuTriggerFor]="userpanel"><i class="material-icons">person</i></button>
      <mat-menu #userpanel="matMenu">
        <strong class="userpanel-username">{{authUser?.current_user?.name}} {{authUser?.current_user?.surname}}</strong>
        <button routerLink="/users/change-password" mat-menu-item>Zmień hasło</button>
        <button routerLink="/logout" mat-menu-item>Wyloguj</button>
      </mat-menu>
    </div>
  </nav>
</div>
