import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './components/app/app.component';
import {RouterModule} from '@angular/router';
import {routes} from './routes';
import {SharedModule} from '../shared/shared.module';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpErrorInterceptor} from "./interceptors/http-error.interceptor";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthInterceptor} from "../features/auth/interceptors/auth.interceptor";
import {registerLocaleData} from "@angular/common";
import localePL from '@angular/common/locales/pl';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter} from "@angular-material-components/datetime-picker";
import {MY_FORMATS} from "../shared/providers/custom-date-formats-providers";
import {NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import { LoaderComponent } from './components/loader/loader.component';
import {LoaderInterceptor} from './interceptors/loader.interceptor';

registerLocaleData(localePL);

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoaderComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
    NgxMatTimepickerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
    {
      provide: NgxMatDateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pl'
    },
  ],
  bootstrap: [AppComponent]
})
export class CoreModule {
}
