import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-page-forbidden',
  templateUrl: './page-forbidden.component.html',
  styleUrls: ['./page-forbidden.component.scss']
})
export class PageForbiddenComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) {
  }

  async ngOnInit() {
    await this.authService.fetchCurrentUser();
  }
}
