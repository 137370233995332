import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../features/auth/services/auth.service";
import {PageForbiddenComponent} from "../../../features/auth/pages/page-forbidden/page-forbidden.component";
import {PageNotFoundComponent} from "../../pages/page-not-found/page-not-found.component";
import {PageLogoutComponent} from "../../../features/auth/pages/page-logout/page-logout.component";
import {PageForgotComponent} from "../../../features/auth/pages/page-forgot/page-forgot.component";
import {PageLoginComponent} from "../../../features/auth/pages/page-login/page-login.component";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isFullLayout = false;

  public constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
  }

  public onActivate(componentToLoad) {
    if (
      componentToLoad instanceof PageNotFoundComponent ||
      componentToLoad instanceof PageForbiddenComponent ||
      componentToLoad instanceof PageForgotComponent ||
      componentToLoad instanceof PageLoginComponent ||
      componentToLoad instanceof PageLogoutComponent
    ) {
      this.isFullLayout = false;
    } else if (this.auth.isAuthenticated()) {
      this.isFullLayout = true;
    }
  }

}
