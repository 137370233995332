import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-logout',
  template: ''
})
export class PageLogoutComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.auth.logout();
    // this.snackbar.success('Wylogowano pomyślnie.');
    this.router.navigate(['/']);
  }
}
