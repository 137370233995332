import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-empty-set',
  templateUrl: './empty-set.component.html',
  styleUrls: ['./empty-set.component.scss']
})
export class EmptySetComponent implements OnInit {
  @Input() icon = 'box';
  @Input() title: string;
  @Input() caption: string;
  @Input() color: string;

  constructor() { }

  ngOnInit(): void {
  }
}
