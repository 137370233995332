import {Routes} from '@angular/router';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {AuthGuard} from "../features/auth/guards/auth.guard";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'investments',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('../features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'investments',
    loadChildren: () => import('../features/investment/investment.module').then(m => m.InvestmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('../features/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'timetable',
    loadChildren: () => import('../features/timetable/timetable.module').then(m => m.TimetableModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('../features/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stats',
    loadChildren: () => import('../features/stats/stats.module').then(m => m.StatsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('../features/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
