<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>

  <div class="reject-comment">
    <mat-form-field *ngIf="data.commentField">
      <mat-label>{{data.commentField}}</mat-label>
      <textarea matInput [(ngModel)]="rejectComment"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button type="button" mat-flat-button color="accent" [mat-dialog-close]>{{data.cancelButton}}</button>
  <button type="button" mat-flat-button color="primary"
          [mat-dialog-close]="confirmValue()"
          [disabled]="data.commentField != '' && data.commentRequired && ! rejectComment"
  >{{data.confirmButton}}</button>
</div>
