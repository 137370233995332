import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService} from '../../../features/auth/services/auth.service';
import {LoggedUser} from '../../../features/auth/interfaces/logged-user';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public authUser: LoggedUser | null;
  guideUrl?: string = null;

  constructor(
    public auth: AuthService,
  ) {
  }

  ngOnInit() {
    if(!this.authUser?.current_user){
      this.auth.fetchCurrentUser().then(() => {
        this.authUser = this.auth.getUser();
      });
    }else{
      this.authUser = this.auth.getUser();
    }
    this.guideUrl = environment?.guide ?? null;
  }
}
