import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackbarService} from '../../../../shared/services/snackbar.service';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
  form = new FormGroup({
    password: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email]),
  });

  returnUrl : string = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
  ) {
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/']);
      return;
    }

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  async onSubmit() {
    if (!this.form.valid) {
      // this.snackbar.error('Podaj numer karty pracownika.');
      return;
    }

    await this.login();
  }

  async onKeyup() {
    if (this.form.value.code.length === 15) {
      await this.login();
    }
  }

  async login() {
    await this.auth.login(this.form.getRawValue()).then((res) => {
      this.auth.setUser(res);
      this.auth.fetchCurrentUser();
      this.router.navigate([this.returnUrl]);
      return true;
    }, (reject) => {
      this.snackbar.error(reject.error.message);
      return false;
    });
  }

  sumbitLogin() {

  }
}
