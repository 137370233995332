<div class="login-form">
  <div class="login-logo"></div>
  <h1>Logowanie</h1>
  <form [formGroup]="form" (submit)="onSubmit()" class="page-content-container">
    <mat-form-field appearance="outline">
      <mat-label>Adres e-mail</mat-label>
      <input matInput formControlName="email" type="text" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Hasło</mat-label>
      <input matInput formControlName="password" type="password" required>
    </mat-form-field>
    <a class="forgot-link" routerLink="/forgot">Zapomniałem hasła</a>
    <button mat-flat-button color="accent">Zaloguj</button>
  </form>
</div>
