import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {InterceptorSkipHeader} from '../classes/const';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public constructor(
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.headers.has(InterceptorSkipHeader) && req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {

        } else if (error.status === 500) {

        }

        return throwError(error);
      })
    );
  }
}
