import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackbar: MatSnackBar
  ) {
  }

  success(message: string, actionMessage ?: string) {
    return this.snackbar.open(message, actionMessage, {
      duration: 3000,
      panelClass: 'snackbar-success'
    });
  }

  error(message: string, actionMessage ?: string) {
    return this.snackbar.open(message, actionMessage, {
      duration: 3000,
      panelClass: 'snackbar-error'
    });
  }

  pending(message: string, actionMessage ?: string) {
    return this.snackbar.open(message, actionMessage, {
      duration: 3000,
      panelClass: 'snackbar-pending'
    });
  }

  open(message: string, action?: string, config?: MatSnackBarConfig) {
    return this.snackbar.open(message, action, config);
  }
}
