<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>

  <mat-form-field style="width:100%">
    <mat-label>Komentarz (opcjonalne)</mat-label>
    <textarea rows="5" matInput [(ngModel)]="message"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button type="button" mat-flat-button color="accent" [mat-dialog-close]>{{data.cancelButton}}</button>
  <button type="button" mat-flat-button color="primary"
          [mat-dialog-close]="confirmValue()"
          [disabled]="data.commentField != '' && data.commentRequired && ! message"
  >{{data.confirmButton}}</button>
</div>
