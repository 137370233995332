import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {SnackbarService} from "../../../../shared/services/snackbar.service";

@Component({
  selector: 'app-page-forgot',
  templateUrl: './page-forgot.component.html',
  styleUrls: ['./page-forgot.component.scss']
})
export class PageForgotComponent implements OnInit {

  form = new FormGroup({
    email: new FormControl('', [Validators.email]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
  }

  saveData() {
    if (!this.form.valid) {
      return;
    }
    this.authService.forgotPassword(this.form.getRawValue()).then((res) => {
      this.snackbar.success('Email z nowym hasłem został wysłany.');
      this.router.navigate(['/login']);
    }).catch((err) => {
      this.snackbar.error('Email z nowym hasłem został wysłany.');
    });

  }


}
