import {Component, Inject, OnInit} from '@angular/core';
import {
  ConfirmMessageDialogDataInterface, ConfirmMessageDialogResponseInterface
} from './confirm-message-dialog-interface';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-message-dialog',
  templateUrl: './confirm-message-dialog.component.html',
  styleUrls: ['./confirm-message-dialog.component.scss']
})
export class ConfirmMessageDialogComponent implements OnInit {
  message = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmMessageDialogDataInterface,
  ) {
    data.title = data.title ? data.title : 'Potwierdź';
    data.message = data.message ? data.message : 'Czy jesteś pewien tej decyzji?';
    data.cancelButton = data.cancelButton ? data.cancelButton : 'Anuluj';
    data.confirmButton = data.confirmButton ? data.confirmButton : 'Tak';
    data.confirmColor = data.confirmColor ? data.confirmColor : 'primary';
    data.commentField = data.commentField ? data.commentField : '';
    data.commentRequired = data.commentRequired ? data.commentRequired : false;
  }

  ngOnInit(): void {
  }

  confirmValue(): ConfirmMessageDialogResponseInterface {
    return {
      confirm: true,
      message: this.message,
    };
  }
}
