import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {InterceptorSkipHeader} from '../../../core/classes/const';
import {AuthService} from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.headers.has(InterceptorSkipHeader) && req.headers.get(InterceptorSkipHeader).indexOf('AUTH') !== -1) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({headers}));
    }

    if (!req.headers.has('Authorization') && this.authService.isAuthenticated()) {
      const token = this.authService.getUser().token;

      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // if (error.error.message === 'Expired JWT Token') {
          //   this.authService.getNewToken().subscribe((res)=>{
          //     req = req.clone({
          //       headers: req.headers.set('Authorization', 'Bearer ' + res.token)
          //     });
          //     // next.handle(req);
          //   });
          // } else {
          //   this.authService.logout();
          //   this.router.navigateByUrl('/login');
          // }

          this.authService.logout();
          this.router.navigateByUrl('/login');

          // this.authService.refreshToken().then(res => {
          //   const request = req.clone({
          //     headers: req.headers.set('Authorization', 'Bearer ' + res.token),
          //   });
          // });

          next.handle(req);
          return of();
        } else if (error.status === 403) {
          this.router.navigateByUrl('/forbidden', {skipLocationChange: false});
          return of();
        }
        return throwError(error);
      })
    );
  }
}
