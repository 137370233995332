<div class="forgot-form">
  <div class="logo"></div>
  <h1>Przypomnij hasło</h1>
  <form [formGroup]="form"  (submit)="saveData()" class="page-content-container">
    <mat-form-field appearance="outline">
      <mat-label>Adres e-mail</mat-label>
      <input matInput formControlName="email" type="text" required>
    </mat-form-field>
    <a class="forgot-link" routerLink="/login">Wróć do logowaina</a>
    <button mat-flat-button color="accent">Wyślij nowe hasło</button>
  </form>
</div>
