import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getPolishPaginatorIntl} from '../core/classes/polish-paginator';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {EmptySetComponent} from './components/empty-set/empty-set.component';
import {ConfirmMessageDialogComponent} from './components/confirm-message-dialog/confirm-message-dialog.component';

@NgModule({
  declarations: [
    SidebarComponent,
    ConfirmDialogComponent,
    ConfirmMessageDialogComponent,
    EmptySetComponent,
  ],
  exports: [
    SidebarComponent,
    ConfirmDialogComponent,
    EmptySetComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    MatMenuModule,
    MatSnackBarModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
  ],
  providers: [
    {provide: MatPaginatorIntl, useValue: getPolishPaginatorIntl()},
  ]
})
export class SharedModule {
}
