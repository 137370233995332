import {Component, Inject, OnInit} from '@angular/core';
import {ConfirmDialogDataInterface, ConfirmDialogResponseInterface} from './confirm-dialog-interface';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  rejectComment = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogDataInterface,
  ) {
    data.title = data.title ? data.title : 'Potwierdź';
    data.message = data.message ? data.message : 'Czy jesteś pewien tej decyzji?';
    data.cancelButton = data.cancelButton ? data.cancelButton : 'Anuluj';
    data.confirmButton = data.confirmButton ? data.confirmButton : 'Tak';
    data.confirmColor = data.confirmColor ? data.confirmColor : 'primary';
    data.commentField = data.commentField ? data.commentField : '';
    data.commentRequired = data.commentRequired ? data.commentRequired : false;
  }

  ngOnInit(): void {
  }

  confirmValue(): ConfirmDialogResponseInterface {
    return {
      confirm: true,
      comment: this.rejectComment,
    };
  }
}
